export default function menu(drawer) {
    
        //  ドロワー
        var state = false;
        var aniDrawer = new TimelineMax();
        $(".drawer-btn").on("click", function() {
            if (state == false) {
                $(this).addClass('active');
                $('.drawer-modal').addClass('open');
                $('.drawer-overlay').addClass('open');

                // アニメーション
                aniDrawer.staggerTo('.drawer-modal-list__item', 1, {
                    opacity: 1
                }, 0.2);

                state = true;
            } else {
                $(this).removeClass('active');
                $('.drawer-modal').removeClass('open');
                $('.drawer-overlay').removeClass('open');

                // アニメーション
                aniDrawer.set('.drawer-modal-list__item', {
                    opacity: 0
                });

                state = false;
            }
        });
    
        $('.close, .drawer-overlay').on('click', function() {
            $('.menu-trigger').click();
        });
}