export default function menu(btnFadeIn) {

    // 途中からボタンを表示
    if(navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)){
        $('.sp-btn').hide();
        $(window).scroll(function() {
            if ($(this).scrollTop() > 800) {
                $('.sp-btn').fadeIn();
            }
            else {
                $('.sp-btn').fadeOut();
            }
        });
    }else{
        $('.link-btn').hide();
        $(window).scroll(function() {
            if ($(this).scrollTop() > 1000) {
                $('.link-btn').fadeIn();
            }
            else {
                $('.link-btn').fadeOut();
            }
        });
    }
}