export default function menu(swiper) {

    var mySwiper = new Swiper ('.swiper-container', {
        effect: 'fade',
        speed: 2400,
        loop: true,
        loopAdditionalSlides: 2,
        autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            reverseDirection: false
        }
    });

}