import $ from 'jquery';
window.$ = window.jQuery = $;


/* Modules
==================== */

// cookie
import cookie from "./modules/cookie";
cookie();

// ドロワーjs
import drawer from "./modules/drawer";
drawer();

// スムーズスクロールjs
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// オープニングアニメーションjs
import opening from "./modules/opening";
opening();

// コンタクトフェードアウトjs
import fadeOut from "./modules/contactFadeOut";
fadeOut();

// topアニメーションjs
import topAnimation from "./modules/topAnimation";
topAnimation();

// スライダーsuraida-js
import swiper from "./modules/swiper";
swiper();

// タブjs
import tabs from "./modules/tabs";
tabs();

// ボタンフェードインjs
import btnFadeIn from "./modules/btnFadeIn";
btnFadeIn();

// フォームjs
import form from "./modules/form";
form();


/* Script
==================== */
objectFitImages();