import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);

export default function menu(topAnimation) {
    // TOP アニメーション
    var controller1 = new ScrollMagic.Controller();

    var greeting = new TimelineMax();
    greeting.to('#greeting', 1, {
        opacity: 1
    })


    var property = new TimelineMax();
    property.to('.property-tabs', 1, {
        opacity: 1
    })


    var about = new TimelineMax();
    about.to('#about', 1, {
        opacity: 1
    })


    var scene1 = new ScrollMagic.Scene({
    triggerElement: "#greeting",
    reverse: false
    })
    .setTween(greeting)
    .addTo(controller1);

    var scene2 = new ScrollMagic.Scene({
    triggerElement: ".property-tabs",
    reverse: false
    })
    .setTween(property)
    .addTo(controller1);

    var scene3 = new ScrollMagic.Scene({
    triggerElement: "#about",
    reverse: false
    })
    .setTween(about)
    .addTo(controller1);

    }