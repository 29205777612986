export default function menu(tabs) {

    // タブ切り替え
    $(".property-tabs-head__btn").on("click",function(){
        var $th = $(this).index();
        $(".property-tabs-head__btn").removeClass("btn-active");
        $(".tabs").removeClass("tab-active");
        $(this).addClass("btn-active");
        $(".tabs").eq($th).addClass("tab-active");
    });
}