export default function menu(opening) {
    
    //  オープニングアニメーション

    //Cookie
    $(function(){
      // 1回目のアクセス
      if($.cookie("access") == undefined) {
        $.cookie("access","onece");
        var a = function(callback) {

            $("body").addClass("scroll-prevent");
            if(navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)){
                $('#typ--sp').fadeIn(2000);
                setTimeout(function(){
                    $('#typ--sp').fadeOut(1000);
                    callback();
                },3000);
            }else{
                const $allMsg = $('#typ');
                const $wordList = $('#typ').html().split("");
                $('#typ').show();
                $('#typ').html("");
                $.each($wordList, function(idx, elem) {
                    const newEL = $("<span/>").text(elem);
                    newEL.appendTo($allMsg);
                    newEL.delay(idx * 120);
                    newEL.animate({ opacity: 1 }, 1200);
                });
                setTimeout(function(){
                    $('#typ').fadeOut(800);
                },3600);
                setTimeout(function(){
                    $('#typ-sub').fadeIn(1200);
                },5200);
                setTimeout(function(){
                    $('#typ-sub').fadeOut(1200);
                    callback();
                },7200);
            }
        };
        
        var b = function(callback) {
            if(navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)){
                setTimeout(function(){
                    $(".header__logo").fadeIn(2000);
                    callback();
                },1000);
            }else{
                setTimeout(function(){
                    $(".header__logo").fadeIn(3000);
                    callback();
                },1000);
            }
        };
        
        var c = function(callback) {
            if(navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)){
                setTimeout(function(){
                    $(".opening").fadeOut(1600);
                    $("body").removeClass("scroll-prevent");
                    callback();
                },2000);
            }else{
                setTimeout(function(){
                    $(".opening").fadeOut(1600);
                    $("body").removeClass("scroll-prevent");
                    callback();
                },2400);
            }
        };

        var end = function() {
            setTimeout(function(){
                $(".header__logo").css("z-index", 99);
            },2000);
        };
        
        /* 呼び出し */
        a(b.bind(null, c.bind(null, end.bind(null))));

      // 2回目以降
        } else {
            //alert("二回目以降です");
            $(".opening").fadeOut(2000);
            $(".header__logo").fadeIn(1000).css("z-index", 99);
        }
    });

}